/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Slideshow, ColumnWrap, ColumnWrapper, Title, Button, Text, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column name={"aque60b3s1m"} style={{"paddingTop":0,"paddingBottom":1,"backgroundColor":"rgba(224, 224, 224, 1)"}}>
        </Column>


        <Column name={"ky96y1khqmb"} style={{"paddingTop":29,"paddingBottom":52}} layout={"l30"}>
        </Column>


        <Slideshow name={"jj5lxmgt82"} autoplay={true} infinite={true}>
          
          <Column className="css-2gix5l --full" style={{"paddingTop":24,"paddingBottom":0}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/e0d58bd6df40423c9e7b7839c14db56b_e=177x1369x969x969__s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
                
                <Title className="title-box fs--102 title-box--invert" content={"Svatební koordinace"}>
                </Title>

                <Button className="btn-box" use={"page"} href={"/koordinace"} content={"Svatební koordinace"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-l5eae6 css-1wbf93s --full pb--60 pt--60" fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/2103f636c5294295bb115262b2e8c9b3_e=4x2x2044x1363__s=3000x_.jpg);
    }
  
background-position: 49% 43%;
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
                
                <Title className="title-box fs--102 title-box--invert" content={"Portrétní focení"}>
                </Title>

                <Button className="btn-box" use={"page"} href={"/fotografieodstelly"} content={"Portrétní focení"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-1ogkjl9 --full pb--60 pt--60" fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/d1dd9332341f4ed3bdf56d7dc608d8ae_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
                
                <Title className="title-box fs--102 title-box--invert" content={"Vánoční focení"}>
                </Title>

                <Button className="btn-box" use={"page"} href={"/vanoce2023"} content={"Chci vědět více"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-172k22y --full pb--60 pt--60" fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_e=334x139x1250x1250__s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
                
                <Title className="title-box fs--102" content={"<span style=\"color: var(--white);\">Svatební koordinace</span>"}>
                </Title>

                <Button className="btn-box" use={"page"} href={"/vanoce2023"} content={"Chci vědět více"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="pb--50" name={"citat"} style={{"paddingTop":51,"backgroundColor":"#e8cec2"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--shape5 --center" style={{"maxWidth":1670}}>
              
              <Title className="title-box title-box--left fs--22" content={"Napsali o nás na FACEBOOKU"}>
              </Title>

              <Text className="text-box fs--24 w--400 lh--16" content={"<span style=\"color: var(--color-blend--05);\">,<span style=\"font-style: italic;\">,Vřele doporučuji. Uvolněná atmosféra, super spolupráce a nádherné prostředí 👍🍀</span>\"</span>"}>
              </Text>

              <Text className="text-box" content={"- Michaela Tomanová"}>
              </Text>

              <Text className="text-box fs--24 w--400 lh--16" content={"<span style=\"color: var(--color-blend--05);\">,,<span style=\"font-style: italic;\">Moc doporučuji🙏😍Vánoční focení proběhlo v krásné atmosféře, holky jsou úžasný a milý ♥️</span>\"</span>"}>
              </Text>

              <Text className="text-box" content={"- Nikola Tomanová"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Služby na míru&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Chcete nafotit sportovní akci, křtiny nebo módní přehlídku? Zkrátka nenašli jste u nás ve standartní nabídce to co jste hledali? Napište nám a společně určitě najdeme vhodné řešení.<br>"}>
              </Text>

              <ContactForm name={"hl1a8dnzfmi"} action={"contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/6557bb07220649809955460dcef8c275_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/4546/6557bb07220649809955460dcef8c275_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/6557bb07220649809955460dcef8c275_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/6557bb07220649809955460dcef8c275_s=860x_.jpg 860w, https://cdn.swbpg.com/t/4546/6557bb07220649809955460dcef8c275_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20" name={"patka"} style={{"paddingTop":35,"backgroundColor":"rgba(224, 224, 224, 1)"}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Ateliér Tůtojc Stod"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":394}} content={"Stella Jarošová<br>IČO:&nbsp;08663696<br>Sídlo:\nMířovice 10,\n333 01 Ves Touškov<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":398}} srcSet={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}